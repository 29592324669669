 
//const baseUrl='http://localhost:8080/api';
//const baseUrl = 'http://37.140.242.137:8080/api'; 
//const baseUrl = 'https:api.cwplatform.com/api'; 
const baseUrl = 'https://api.cwplatform.com/api'; 
//const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';


console.log('baseUrl:',baseUrl);


const loginUrl = baseUrl + '/Login/SignIn';
const validateTokenUrl = baseUrl + '/Login/ValidateToken';

const getAllCustomersUrl = baseUrl + '/Customer/GetAllWithFiltersAndPagination';
const getCustomerOptionsUrl = baseUrl + '/Customer/GetOptionsMetadata';
const createCustomerUrl = baseUrl + '/Customer/Create';
const updateCustomerUrl = baseUrl + '/Customer/Update';
const deleteCustomerUrl = baseUrl + '/Customer/Delete';
const getCustomerByIdUrl = baseUrl + '/Customer/GetById';

// Order URLs
const getAllOrdersUrl = baseUrl + '/Order/GetAllWithFiltersAndPagination';
const getOrderOptionsUrl = baseUrl + '/Order/GetOptionsMetadata';
const createOrderUrl = baseUrl + '/Order/Create';
const updateOrderUrl = baseUrl + '/Order/Update';
const deleteOrderUrl = baseUrl + '/Order/Delete';
const getOrderByIdUrl = baseUrl + '/Order/GetById';

// Stock URLs
const getAllStocksUrl = baseUrl + '/Stock/GetAllWithFiltersAndPagination';
const getStockOptionsUrl = baseUrl + '/Stock/GetOptionsMetadata';
const createStockUrl = baseUrl + '/Stock/Create';
const updateStockUrl = baseUrl + '/Stock/Update';
const deleteStockUrl = baseUrl + '/Stock/Delete';
const getStockByIdUrl = baseUrl + '/Stock/GetById';

// Warehouse URLs
const getAllWarehouseUrl = baseUrl + '/Warehouse/GetAllWithFiltersAndPagination';
const getWarehouseOptionsUrl = baseUrl + '/Warehouse/GetOptionsMetadata';
const createWarehouseUrl = baseUrl + '/Warehouse/Create';
const updateWarehouseUrl = baseUrl + '/Warehouse/Update';
const deleteWarehouseUrl = baseUrl + '/Warehouse/Delete';
const getWarehouseByIdUrl = baseUrl + '/Warehouse/GetById';

const UrlLinks = {
  baseUrl,loginUrl,validateTokenUrl,
  getAllCustomersUrl, getCustomerOptionsUrl, createCustomerUrl, updateCustomerUrl, deleteCustomerUrl, getCustomerByIdUrl,
  getAllOrdersUrl, getOrderOptionsUrl, createOrderUrl, updateOrderUrl, deleteOrderUrl, getOrderByIdUrl,
  getAllStocksUrl, getStockOptionsUrl, createStockUrl, updateStockUrl, deleteStockUrl, getStockByIdUrl,
  getAllWarehouseUrl,getWarehouseOptionsUrl,createWarehouseUrl,updateWarehouseUrl,deleteWarehouseUrl,getWarehouseByIdUrl     
};

export default UrlLinks;